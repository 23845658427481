<template>
  <!--<fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">-->
  <div>
    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <div v-if="!isLoading" class="mt-9 pb-5" key="content">
      <div class="text-xl text-left text-gray-700 pb-4 flex">
      <p>
        <font-awesome-icon icon="envelope-open-text" class="mr-2"/>
        Электронная почта:</p>
      <img src="@/assets/img/dark-email.png">
    </div>
      
      <p 
        class="block text-3xl text-secondary text-left py-5 pr-2 md:pr-4 lg:pr-8"
        style="line-height: 1.2"
      >Обратная связь</p> 
      <SendMessage />
    </div>
  </div>
  <!--</fade-transition>-->
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import SendMessage from '@/components/SendMessage.vue'

export default {
  name: 'ThAreaContacts',
  components: {
    SendMessage
  },
  data: function() {
    return {
      isLoading: false/*true*/,
    }
  }
}
</script>

<style>
</style>
